

































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import BreadCrumbs from '@/components/common/BreadCrumbs.vue'
import Cookie from 'js-cookie'
import { getCenterList } from '@/api/modules/project'
import { getPatientQuestionDataDetail } from '@/api/modules/patient'
@Component({
  components: {
    BreadCrumbs
  }
})
export default class extends Vue {
  private formData: any = {};
  private formName: string = '';
  private pictureList: object = {};
  private visible: boolean = false;
  private large: string = '';
  private loading = true;

  get projectName (): number {
    return Cookie.get('projectName')
  }
  get patientNumber (): any {
    return this.$route.query.patientNumber
  }
  get centerName (): any {
    return this.$route.query.centerName
  }
  get visitName (): any {
    return this.$route.query.visitName
  }
  get createTime (): any {
    return this.$route.query.createTime
  }
  private getDetailData () {
    let data = {
      patientId: this.$route.query.patientId,
      questionnaireId: this.$route.query.questionnaireId
    }
    getPatientQuestionDataDetail(data).then((res: any) => {
      this.formName = res.data.name
      this.formData = res.data.formList
      this.pictureList = res.data.pictureList
    })
  }
  private enlarge (url: string) {
    this.visible = true
    this.large = url
  }
  created () {
    this.getDetailData()
  }
}
